// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-strapi-category-slug-js": () => import("./../../../src/pages/categories/{StrapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-categories-strapi-category-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-memes-index-js": () => import("./../../../src/pages/memes/index.js" /* webpackChunkName: "component---src-pages-memes-index-js" */),
  "component---src-pages-memes-strapi-meme-slug-js": () => import("./../../../src/pages/memes/{StrapiMeme.slug}.js" /* webpackChunkName: "component---src-pages-memes-strapi-meme-slug-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

